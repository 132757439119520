import NextLink from 'next/link';

export interface LinkProps {
  href: string;
  style?: string;
  target?: string;
  title: string;
}

const Link: React.FC<LinkProps> = ({ href, style, target, title }) => {
  return href.startsWith('/') ? (
    <NextLink
      data-testid="next-link"
      className={style}
      href={href}
      target={target}
    >
      {title}
    </NextLink>
  ) : (
    <a data-testid="a-link" className={style} href={href} target={target}>
      {title}{' '}
    </a>
  );
};

export default Link;
