import Image from 'next/image';

import close from '@/public/images/close.svg';
import closeBlue from '@/public/images/close-blue.svg';

interface CloseButtonProps {
  alt?: string;
  ariaLabel?: string;
  color?: 'white' | 'blue';
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  alt = 'Close',
  ariaLabel = 'Close',
  color = 'white',
  onClick,
  title = 'Close',
}) => {
  return (
    <button
      aria-label={ariaLabel}
      className="close-btn cursor-pointer h-[18px] w-[18px]"
      onClick={onClick}
      role="button"
      title={title}
    >
      <Image
        className="close-btn__icon"
        alt={alt}
        src={color === 'white' ? close : closeBlue}
        height={18}
        width={18}
      />
      <Image
        alt={alt}
        className="close-btn__icon--hover"
        src={color === 'white' ? closeBlue : close}
        height={18}
        width={18}
      />
    </button>
  );
};
export default CloseButton;
