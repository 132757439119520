import { MenuItem, MenuItemWithSubMenu } from '@/types.d';

const menuItems: (MenuItem | MenuItemWithSubMenu)[] = [
  {
    title: 'Our story & team',
    href: '/our-story',
    subMenu: [
      {
        title: 'News',
        href: '/news',
      },
    ],
  },
  {
    title: 'Services',
    href: '/services',
    subMenu: [
      {
        title: 'Recovery coaching',
        href: 'https://calendly.com/passengerrecovery',
        target: '_blank',
      },
      {
        title: 'Events',
        href: '/services#events',
      },
    ],
  },
  {
    title: 'Toolbox',
    href: null,
    subMenu: [
      {
        title: 'Online resources',
        href: '/resources',
      },
      {
        title: 'Partners',
        href: '/resources#partners',
      },
      {
        title: 'Online meetings',
        href: '/online-meetings',
      },
      {
        title: 'Thought of the day',
        href: 'https://www.hazeldenbettyford.org/thought-for-the-day',
        target: '_blank',
      },
    ],
  },
  {
    title: 'Find a meeting',
    href: '/compass',
  },
  {
    title: 'Contact us',
    href: '/contact',
  },
];

const adminMenuItems = [
  {
    title: 'Add meetings',
    href: '/compass/admin/add-meeting',
    type: 'add',
  },
  {
    title: 'Upload meetings',
    href: '/compass/admin/upload-meetings',
    type: 'upload',
  },
  {
    title: 'Edit meetings',
    href: '/compass/admin/edit-meetings',
    type: 'edit',
  },
  {
    title: 'Reported meetings',
    href: '/compass/admin/reported-meetings',
    type: 'reports',
  },
];

export { menuItems, adminMenuItems };
